import React from 'react'
import Layout from "../components/Layout"
import styled from "styled-components"
import { theme } from "../components/Shared/styles-global"
import { setThemeVars } from "../util/theme-helper"
import BlockRobot from '../components/BlockRobot'

class PasswordChange extends React.Component {
  state={
    old_password: "",
    new_password: "",
    message: "",
  }
  handleUpdate=(e)=>{
    e.preventDefault()
    this.setState({[e.target.name]: e.target.value})
  }
  handleSubmit = (event)=>{
    event.preventDefault()

    const url = "https://manjubox.net/php/coefont_uuid.php"
    const data = new FormData();
    data.append("method", "register");
    data.append("user", this.props.username)
    data.append("password", this.state.new_password);
    data.append("old_password", this.state.old_password);

    fetch(url, {
      method: "POST",
      body: data,
    })
    .then(response => response.json())
    .then(json => {
      if(json.status === "success"){
        this.setState({old_password:"",new_password:"", message: json.message})
      }
      else {
        this.setState({message: json.message})
      }
    })
    .catch(error => {
      this.setState({message: error})
    })
  }

  render(){
    return (
      <StyledUUidCardDiv>
        <StyledForm
          method='post'
          onSubmit={this.handleSubmit}>
          <table>
            <tbody>
              <tr>
                <td>現在のパスワード</td>
                <X3Td><input type="password" name="old_password" value={this.state.old_password} onChange={this.handleUpdate} className='descbox'/></X3Td>
              </tr>
              <tr>
                <td>新しいパスワード</td>
                <X3Td><input type="password" name="new_password" value={this.state.new_password} onChange={this.handleUpdate} className='descbox'/></X3Td>
              </tr>
            </tbody>
          </table>
          <div className='buttons'>
            <span>{this.state.message}</span>
            <button type="submit" disabled={this.state.old_password.length == 0 || this.state.new_password.length == 0}>登録</button>
          </div>
        </StyledForm>
      </StyledUUidCardDiv>
    );
  }
}
const StyledForm = styled.form`
select{
    color:${()=>setThemeVars( theme.fontColorLight,theme.fontColorDark)};
    background:${()=>setThemeVars( theme.bgColorLight,theme.bgColorDark)};
}
input[type=text],input[type=password],input[type=email]{
    color:${()=>setThemeVars( theme.fontColorLight,theme.fontColorDark)};
    background:${()=>setThemeVars( theme.bgColorLight,theme.bgColorDark)};
    border:solid 1px ${()=>setThemeVars( theme.fontSubColorLight,theme.fontSubColorDark)};
}
textarea{
    color:${()=>setThemeVars( theme.fontColorLight,theme.fontColorDark)};
    background:${()=>setThemeVars( theme.bgColorLight,theme.bgColorDark)};
    border:solid 1px ${()=>setThemeVars( theme.fontSubColorLight,theme.fontSubColorDark)};
}
button{
    color:${()=>setThemeVars( theme.fontColorLight,theme.fontColorDark)};
    background:${()=>setThemeVars( "#eeeeee","#222224")};
    border:solid 1px ${()=>setThemeVars( theme.fontSubColorLight,theme.fontSubColorDark)};

    :hover{
        background:${()=>setThemeVars( "#dddddd","#111112")};
    }
}
`

class RegisterCard extends React.Component {
  state={
    uuid:"",
    desc:"",
    message:"",
    isEnabled:false,
  }
  handleUpdate=(event)=>{
    const isEnabled = event.target.name == "uuid" ? this.validateUUID(event.target.value) : this.validateUUID(this.state.uuid)

    this.setState({
      [event.target.name]: event.target.value,
      isEnabled:isEnabled,
      message: isEnabled ? "" : "UUIDは xxxxxxxx-xxxx-xxxx-xxxxxxxxxxxx のフォーマットである必要があります。",
    })
  }
  validateUUID = (uuid)=>{
    return uuid.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i)
  }
  handleSubmit = (event)=>{
    event.preventDefault()
    if(this.state.uuid == ""){
      return
    }
    
    const url = "https://manjubox.net/php/coefont_uuid.php"
    const data = new FormData();
    data.append("method", "add_uuid");
    data.append("uuid", this.state.uuid);
    data.append("desc", this.state.desc);
  
    fetch(url, {
      method: "POST",
      body: data,
    })
    .then(response => response.json())
    .then(json => {
      if(json.status == "success"){
        this.props.onAdd(this.state.uuid, this.state.desc, json.register_date)
        this.setState({uuid:"",desc:"",message:"登録しました。",isEnabled:false});
      }else{
        this.setState({message: json.message})
      }
    })
    .catch(error=>{
      this.setState({message: error})
    })
  }
  render(){
    return (
      <StyledUUidCardDiv>
        <StyledForm
          method='post'
          onSubmit={this.handleSubmit}>
          <table>
            <tbody>
            <tr>
              <td>UUID（必須）</td>
              <X3Td><input type="text" name="uuid" value={this.state.uuid} onChange={this.handleUpdate} className='descbox'/></X3Td>
            </tr>
            <tr>
              <td>備考</td>
              <X3Td><textarea rows={this.state.desc.split("\n").length} type="text" name="desc" value={this.state.desc} onChange={this.handleUpdate} className='descbox'/></X3Td>
            </tr></tbody>
          </table>
          <div className='buttons'>
            <span>{this.state.message}</span>
            <button type="submit" disabled={!this.state.isEnabled}>登録</button>
          </div>
        </StyledForm>
      </StyledUUidCardDiv>
    );
  }
}
const X3Td = styled.td`
  width:85%;
`

class UuidCard extends React.Component {
  state={
    desc: "",
    register_user: "",
    register_date: "",
    isChanged:false,
    message: "",
  }
  constructor(props){
    super(props)
    this.state={
      desc:props.desc,
      register_user:props.register_user,
      register_date:props.register_date,
      isChanged:false,
      message: "",
    };
  }
  handleUpdate=(event)=>{
    this.setState({
      isChanged: true,
      [event.target.name]: event.target.value,
    })
  }
  handleSaveSubmit = (event)=>{
    event.preventDefault()

    const url = "https://manjubox.net/php/coefont_uuid.php"
    const data = new FormData();
    data.append("method", "add_uuid");
    data.append("uuid", this.props.uuid);
    data.append("desc", this.state.desc);

    fetch(url, {
      method: "POST",
      body: data,
    })
    .then(response => response.json())
    .then(json => {
      if(json.status == "success"){
        this.setState({isChanged: false, message:"保存しました。"});
      }else{
        this.setState({message: json.message})
      }
    })
    .catch(error=>{
      this.setState({message: error})
    })
  }
  handleDeleteSubmit = (event)=>{
    event.preventDefault()

    const url = "https://manjubox.net/php/coefont_uuid.php"
    const data = new FormData();
    data.append("method", "delete_uuid");
    data.append("uuid", this.props.uuid);

    fetch(url, {
      method: 'POST',
      body: data,
    })
    .then(response => response.json())
    .then(json => {
      if(json.status === "success"){
        this.props.onDelete(this.props.uuid)
      }
      else{
        this.setState({message: json.message})
      }
    })
  }
  render(){
    return (
      <StyledUUidCardDiv>
        <StyledForm>
          <h3>{this.props.uuid}</h3>
          <span className='info'>by {this.state.register_user} at {this.state.register_date}</span>
          <div>
            <table>
              <tbody>
              <tr>
                <td>備考</td>
                <X3Td><textarea rows={this.state.desc.split("\n").length} name="desc" value={this.state.desc} onChange={this.handleUpdate} className='descbox'/></X3Td>
              </tr>
              </tbody>
            </table>
            <div className='buttons'>
                  <span>{this.state.message}</span>
                  {this.state.isChanged ? <button type="submit" onClick={this.handleSaveSubmit}>保存</button> : null}
                  <button type="submit" onClick={this.handleDeleteSubmit}>削除</button>
            </div>
          </div>
        </StyledForm>
      </StyledUUidCardDiv>
    )
  }
}
const StyledUUidCardDiv = styled.div`
  padding: 1rem;
  .descbox{
    display: block;
    width:100%;
  }
  .buttons{
    display:fiex;
    justify-content: flex-end;
  }
  button{
    width:5rem;
  }
  table{
    display: table;
    table-layout: fixed;
  }
  :hover{
    background-color:${() => setThemeVars(theme.bgSubColorLight, theme.bgSubColorDark)};
  }
  textarea{
    margin-left:0.5rem;
    min-height:1.2rem;
    padding:0.1rem;
    resize:vertical;
  }
  h3{
    display:inline-block;
  }
  .info{
    margin-left:1rem;
  }
`

class ManagePage extends React.Component {
  state = {
    mode : "new_uuid",
    uuids: [],
    message: "",
  }
  handleAdd=(uuid, desc, register_date)=>{
    this.setState({uuids: [{uuid:uuid, desc:desc, register_user:this.props.username, register_date:register_date}, ...this.state.uuids.filter(x=>x.uuid!=uuid)]})
  }
  handleDelete=(uuid)=>
  {
    this.setState({uuids:this.state.uuids.filter(x=>x.uuid != uuid)})
  }

  handleLogout =(event)=>{
    event.preventDefault()

    const url = "https://manjubox.net/php/coefont_uuid.php"
    const data = new FormData();
    data.append("method", "logout");
    
    fetch(url, {
      method: 'POST',
      body: data,
    })
    .then(response => response.json())
    .then(json => {
      if(json.status === "success"){
        this.props.onLogout()
      }
    })
  }

  componentDidMount(){
    this.setState({message:"読み込み中..."})
    
    const url = "https://manjubox.net/php/coefont_uuid.php"
    const data = new FormData();
    data.append("method", "get_uuids");

    fetch(url, {
      method: 'POST',
      body: data,
    })
    .then(response => response.json())
    .then(json => {
      if(json.status == "success"){
        this.setState({uuids:json.uuids, message:""})
      }
      else{
        this.setState({message: json.message})
      }
    })
    .catch(error=>{
      this.setState({message: error})
    })
  }

  handleSwitchMode=(event,mode)=>{
    event.preventDefault()
    this.setState({mode:mode})
  }
  render(){
    return (
      <StyledManagePageDiv>
        <h1>ログイン中 : {this.props.username}</h1>
        {
          this.state.mode == "new_uuid" ? 
          <div>
            <h2>新規登録 / <a href="/" onClick={(event)=>this.handleSwitchMode(event,"change_password")}>パスワードを変更</a> / <a href="/" onClick={this.handleLogout}>ログアウト</a></h2>
            <RegisterCard onAdd={this.handleAdd}/>
          </div>
          :
          <div>
            <h2><a href="/" onClick={(event)=>this.handleSwitchMode(event,"new_uuid")}>新規登録</a> / パスワードを変更 / <a href="/" onClick={this.handleLogout}>ログアウト</a></h2>
            <PasswordChange username={this.props.username}/>
          </div>
        }

        <h2>登録済み一覧</h2>
        <div>
          {this.state.message}
          {this.state.uuids.map((value) => 
            <UuidCard key={value.uuid+value.desc}
              uuid={value.uuid}
              desc={value.desc}
              register_user={value.register_user}
              register_date={value.register_date}
              onDelete={this.handleDelete}/>)}
        </div>
      </StyledManagePageDiv>
    );
  }
}

const StyledManagePageDiv = styled.div`
  h2>a{
    color:steelblue;
  }
`

class LoginPage extends React.Component {
  state = {
    is_logged_in: false,
    username: '',
    password: '',
    message: '',
  }
  
  handleUpdate=(event)=>{
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  handleSubmit = (event)=>{
    event.preventDefault()
    
    const url = "https://manjubox.net/php/coefont_uuid.php"

    const data = new FormData();
    data.append('method', 'login');
    data.append('user', this.state.username);
    data.append('password', this.state.password);

    const options = {
      method: 'POST',
      body: data,
    }

    fetch(url, options)
    .then(response => response.json())
    .then(json => {
      if(json.status == "success"){
        this.setState({
          is_logged_in: true
        })
      }else{
        this.setState({
          message: json.message,
        })
      }
    })
    .catch(error=>{
      this.setState({
        message: "サーバーとの通信に失敗しました。: " + error,
      })
    })

  }
  handleLogout = ()=>{
    this.setState({is_logged_in: false, username: '', password: ''})
  }
  render(){
    if(this.state.is_logged_in)
    {
      return (
        <Layout sidebar={false}>
          <BlockRobot/>
          <StyledDiv>
            <ManagePage username={this.state.username} onLogout={this.handleLogout}/>
          </StyledDiv>
        </Layout>)
    }
    else
    {
      return (
        <Layout sidebar={false}>
          <BlockRobot/>
          <StyledDiv>
            <h1>ログイン</h1>
            <StyledForm
              method="post"
              onSubmit={this.handleSubmit}>
                <label>
                  ユーザー名
                  <input type="text" name="username" value={this.state.username} onChange={this.handleUpdate}/>
                </label>
                <label>
                  パスワード
                  <input type="password" name="password" value={this.state.password} onChange={this.handleUpdate}/>
                </label>
                <button className="button" type="submit">ログイン</button>
                <p><strong>{this.state.message}</strong></p>
            </StyledForm>
          </StyledDiv>
        </Layout>
      )
    }
  }
}
const StyledDiv = styled.div`
  margin: 3rem;
  H1{
    margin-bottom: 0.5rem;
  }
  form{
    label{
      display: block;
      margin-bottom: 0.5rem;
    }
    input{
      margin-left: 0.5rem;
    }
    button{
      width:5rem;
    }
  }
`

export default LoginPage