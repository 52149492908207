import React from "react"
import Helmet from "react-helmet"

function BlockRobot() {
  return <Helmet
        meta={[
          {
            name: `robots`,
            content: "noindex,nofollow",
          },
        ]}
      />
}

export default BlockRobot
